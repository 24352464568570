import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const RulerIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.main} strokeWidth="6" {...props}>
    <rect
      fill="none"
      stroke="#E10F14"
      x="28.4"
      y="111"
      width="203.7"
      height="39.9"
      strokeWidth="7"
      strokeLinejoin="round"
    />
    <g fill="none" stroke="#E10F14" strokeLinecap="round">
      <line x1="45.5" y1="114.4" x2="45.5" y2="122.2" />
      <line x1="74.2" y1="114.4" x2="74.2" y2="122.2" />
      <line x1="102.9" y1="114.4" x2="102.9" y2="122.2" />
      <line x1="131.7" y1="114.4" x2="131.7" y2="122.2" />
      <line x1="160.4" y1="114.4" x2="160.4" y2="122.2" />
      <line x1="186.9" y1="114.4" x2="186.9" y2="122.2" />
      <line x1="215.6" y1="114.4" x2="215.6" y2="122.2" />
    </g>
    <circle cx="45.2" cy="136.6" r="5.7" />
  </svg>
)

export default RulerIcon
