import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const ParticipantIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" {...props} fill={COLORS.light}>
    <path d="M113.9 144.7c0-3.9-3.2-7.1-7.1-7.1s-7.1 3.2-7.1 7.1 3.2 7.1 7.1 7.1c3.9 0 7.1-3.2 7.1-7.1m81-22.1c.9-.8 1.5-1.9 1.5-3.2v-13.2c0-2.1-1.6-3.9-3.7-4.2l-5.3-.6c-2-14.9-9.7-28-20.8-37.1a4.2 4.2 0 00-6.9 3.2c0 1.3.6 2.4 1.6 3.3a49.32 49.32 0 0118.1 34.7c.1 2 1.8 3.7 3.8 3.9l4.8.6v6.1c-18.3 4.1-37.7 6.2-57.7 6.2s-39.4-2.1-57.7-6.2V110l4.8-.6c2-.2 3.7-1.8 3.8-3.9 1-14 7.9-26.3 18.1-34.7a4.19 4.19 0 10-5.3-6.5 57.98 57.98 0 00-20.8 37.1l-5.3.6c-2.1.3-3.7 2-3.7 4.2v13.2c0 1.3.6 2.4 1.5 3.2-6.3 1.4-10.5 7.6-9.4 14.1l3.2 18.7c1 6 6.1 10.3 12.2 10.3.7 0 1.4-.1 2.1-.2.6-.1 1.2-.3 1.8-.5 3.6 15.3 10 27.3 19.1 35.9 15.3 14.5 32.9 14.1 35.7 14h1.6c5.3 0 20.6-1.2 34.1-14 9.1-8.6 15.5-20.7 19.1-35.9a12.09 12.09 0 003.9.7c6 0 11.2-4.3 12.2-10.3l3.1-18.7c1-6.5-3.2-12.7-9.5-14.1M72.3 157.2c-2.2.4-4.2-1.1-4.6-3.3l-3.2-18.7c-.4-2.2 1.1-4.2 3.3-4.6.2 0 .4-.1.7-.1 1.5 0 2.8.8 3.5 2.1.1 8.6.7 16.6 1.9 23.9-.5.4-1 .6-1.6.7m88.1 37.5c-13.5 12.9-29.6 11.8-29.7 11.8h-.8c-.2 0-16.2 1.1-29.7-11.8-14-13.4-20.7-36.5-19.8-68.6 16 3 32.7 4.5 49.9 4.5s33.9-1.5 49.9-4.5c.8 32.2-5.8 55.3-19.8 68.6m35.6-59.4l-3.1 18.7c-.4 2.1-2.4 3.6-4.6 3.3-.6-.1-1.1-.3-1.5-.6 1.2-7.3 1.8-15.3 1.9-23.9.7-1.3 2-2.1 3.5-2.1.2 0 .4 0 .7.1 2 .2 3.5 2.3 3.1 4.5m-42.2 2.3c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1c3.9 0 7.1-3.2 7.1-7.1s-3.2-7.1-7.1-7.1m-11.4 37.2c-2.8 1.9-7.3 3-12.1 3s-9.4-1.1-12.1-3a4.18 4.18 0 00-4.7 6.9c4.2 2.8 10.3 4.4 16.8 4.4s12.7-1.6 16.8-4.4c1.9-1.3 2.4-3.9 1.1-5.8a4.14 4.14 0 00-5.8-1.1M119.7 58.2c0-2.3 1.9-4.2 4.2-4.2h12.9c2.3 0 4.1 1.9 4.1 4.2v39.9c0 2.3 1.9 4.2 4.2 4.2 2.3 0 4.2-1.9 4.2-4.2V58.2c0-6.9-5.6-12.5-12.5-12.5h-12.9c-6.9 0-12.5 5.6-12.5 12.5v39.9c0 2.3 1.9 4.2 4.2 4.2 2.3 0 4.2-1.9 4.2-4.2-.1 0-.1-39.9-.1-39.9z" />
  </svg>
)

export default ParticipantIcon
