import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const CartIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 133.66 113.44" height={22} fill={COLORS.main} {...props}>
    <title>Panier</title>
    <path
      stroke={COLORS.main}
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M34.34 47.24H6.65l22.16 60.54h76.41l21.79-60.54z"
    />
    <path d="M66.83 54.24a6 6 0 01-6-6V8a6 6 0 0112 0v40.24a6 6 0 01-6 6z" />
  </svg>
)

export default CartIcon
