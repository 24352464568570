import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const TimeIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" {...props} fill={COLORS.light}>
    <path d="M75.8 83l5.6 5.9c-.5.7-1.1 1.5-1.7 2.4-20.1 27.8-15.7 66.8 10.1 89.1 8.7 7.5 19 11.9 30.3 13.6 12.1 1.9 24 .6 35.2-4.4 19.1-8.4 31.1-23.4 35.9-44.1 4.6-19.8 1.4-38.4-10.8-54.8-9.6-12.8-22.7-20.5-38.1-23.7-8.9-1.9-17.9-1.8-26.8.2-2.6.6-4.7-.7-5.3-3.1-.6-2.6.5-4.4 3.4-5 23.2-4.7 44.2.1 62.5 15.7 13.8 11.8 22.2 26.9 24.9 45 2.9 20-2.2 38-13.9 54.1-10.4 14.3-23.9 23.9-41 27.8-37.4 8.4-77.5-14.3-85.6-56.8-4.3-22.6 1-43 14.7-61.2.1-.2.3-.3.4-.4 0-.2.2-.3.2-.3" />
    <path d="M134.2 114.1c0 4.2.1 8.4 0 12.6 0 1.4.4 2.4 1.4 3.4 10.1 10.3 20.1 20.6 30.1 30.9 1.4 1.4 2.3 2.9 1.5 5-1.2 3.1-4.5 3.7-6.9 1.3-3.2-3.1-6.3-6.4-9.4-9.6-7.6-7.7-15.1-15.5-22.7-23.2a6.83 6.83 0 01-2.2-5.5c.1-9.2 0-18.3 0-27.5 0-3.1 1.6-5 4.2-4.9 2.6 0 4.1 1.8 4.1 5-.1 4-.1 8.3-.1 12.5M83 67.3h8.9c2.7 0 4.4 1.7 4.4 4.5.1 6 0 12 0 18 0 1.9-.7 3.5-2.5 4.3-1.9.8-3.5.3-4.9-1.2C83 87 77.2 81 71.3 75c-1.4-1.4-2.1-3-1.3-5.1.8-2.1 2.4-2.7 4.5-2.6 2.8.1 5.7 0 8.5 0" />
  </svg>
)

export default TimeIcon
