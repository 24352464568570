import { BREAKPOINTS } from "src/constants"

export const isBrowser = (): boolean => typeof window !== "undefined"

export const isMobile = (): boolean => !isBrowser() || window.innerWidth < BREAKPOINTS.mobile

export const getScroll = (): number => {
  if (isBrowser()) {
    return (
      window.pageYOffset ||
      ((document.documentElement || document.body.parentNode || document.body) as HTMLElement).scrollTop ||
      0
    )
  }
  return 0
}

export const smoothScroll = (element: HTMLElement, offset = 0, duration = 500): void => {
  if (window.jump) {
    // jump.js polyfill => no scrollBehavior support
    window.jump(element, {
      offset,
      duration,
    })
  } else {
    const { top } = element.getBoundingClientRect()
    window.scroll({
      top: getScroll() + top + offset,
      behavior: "smooth",
    })
  }
}

export const scrollToRef = (el: HTMLElement): void => {
  if (!el) {
    return
  }
  setTimeout(() => {
    const scroll = getScroll()
    if (scroll || !el) {
      return
    }
    smoothScroll(el, el.clientHeight - window.innerHeight + (isMobile() ? 20 : 30))
  }, 300)
}
