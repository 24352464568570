import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const ToolsIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.main} {...props}>
    <path
      d="M67.9,201.9c-1.9,0-4.2-0.5-6.2-2.6c-2.4-2.5-3-5.7-1.9-9.6c0.9-3.1,1.8-6.2,2.7-9.3
			c1.3-4.8,2.7-9.7,4.2-14.5c0.8-2.7,2.4-5.4,4.3-7.3l1.9-1.9c27.7-27.8,55.4-55.4,83.1-83.1l10.8-10.8c2.8-2.8,6.3-4.2,11-4.1
			c3.1,0,6.4,1.5,9.1,4.2l1.3,1.3c3.3,3.2,6.5,6.5,9.7,9.7c6.4,6.5,6.3,14.6-0.1,21.1l-9.2,9.2c-28.1,28.1-56.3,56.3-84.5,84.3
			l-1.6,1.6c-1.9,1.9-4.5,3.4-7.5,4.4c-5.5,1.7-11.1,3.3-16.5,4.8c-2.4,0.7-4.8,1.4-7.2,2C70.8,201.5,69.5,201.9,67.9,201.9z
			 M177.4,66.2c-3,0-4.3,0.9-5.3,1.9l-10.8,10.8c-27.7,27.7-55.4,55.4-83.1,83.1l-1.9,1.9c-1,1-2,2.6-2.4,4.2
			c-1.5,4.7-2.9,9.6-4.2,14.3c-0.9,3.1-1.8,6.2-2.7,9.4c-0.5,1.6-0.1,2,0.2,2.3c0.2,0.3,0.5,0.5,2.2,0c2.4-0.7,4.8-1.4,7.2-2.1
			c5.4-1.5,10.9-3.1,16.3-4.8c1.8-0.6,3.4-1.5,4.4-2.5l1.6-1.6c28.2-28,56.3-56.2,84.4-84.3l9.2-9.2c3.5-3.6,3.6-7,0-10.6
			c-3.2-3.3-6.4-6.5-9.6-9.7l-1.3-1.3c-1.5-1.5-3-2-3.9-2l0,0C177.6,66.2,177.5,66.2,177.4,66.2z"
    />
    <path
      d="M101.4,123.9l-2.5-1.8c-2.5-1.8-5.9-2.4-10.8-2.3c-0.3,0-0.5,0-0.8,0c-17.5,0-32.2-14.1-32.8-31.8
			c0-0.7,0-1.4,0.1-2.1c0-0.3,0.1-0.6,0.1-1c0.1-4.6,2.9-6.3,4.6-6.9c4.1-1.5,6.9,1.4,7.7,2.3c2.2,2.3,4.4,4.4,6.6,6.7l1.5,1.5
			c2.8,2.8,5.3,2.8,8.1,0c0.9-0.9,1.9-1.9,2.7-2.7c1.2-1.1,2.3-2.3,3.4-3.3c2.4-2.5,2.5-4.8,0.1-7.4c-2.2-2.3-4.4-4.4-6.6-6.7
			l-2.1-2.1c-2.3-2.4-3.1-4.9-2.2-7.5c0.7-2,2.3-4.3,6.7-4.6c8.5-0.8,17.2,2.2,24,8.1c6.8,5.9,10.8,14.2,11.1,22.7v0.5
			c0,0.9,0.1,1.8,0,2.5c-0.4,4.1,0.5,7.4,2.4,10.4l1.7,2.4l-8.7,9.2c-1.1,1.1-3.4,3.2-5.9,5.5c-2.6,2.4-5.3,4.9-6.5,6.1L101.4,123.9
			z M89.2,112.5c3.4,0,7.4,0.4,11.2,2.2c1.4-1.3,3-2.8,4.7-4.4c2.3-2.2,4.6-4.2,5.7-5.3l4.7-5c-1.9-3.7-2.6-7.9-2.2-12.5
			c0-0.5,0-1.1,0-1.6v-0.6c-0.2-6.6-3.4-13-8.6-17.6c-5.2-4.5-11.8-6.9-18.3-6.4l1.8,1.8c2.3,2.3,4.5,4.5,6.7,6.8
			c5.2,5.5,5.1,12.1-0.1,17.5c-1.2,1.2-2.3,2.3-3.4,3.4c-0.9,0.9-1.9,1.9-2.7,2.7c-5.7,5.7-12.7,5.7-18.4,0l-1.5-1.5
			c-2.2-2.2-4.3-4.3-6.5-6.6c0,0.3,0,0.6-0.1,0.9c0,0.4-0.1,0.8-0.1,1.2c0.5,14,12.2,25.2,26,24.8C88.4,112.5,88.8,112.5,89.2,112.5
			z"
    />
    <path
      d="M174,206.6c-15.7,0-29.3-11.3-32.1-27c-0.4-2.4-0.6-4.8-0.5-7.4c0.2-4.1-0.6-7.3-2.5-9.9l-1.8-2.4l2.2-2.2
			c1.2-1.2,3.6-3.6,6.1-6.1c2.4-2.4,4.7-4.7,5.9-5.9l9.1-9l2.5,1.9c2.9,2.2,6.9,2.3,11.4,2.4h1c8.9,0.2,17.5,4.1,23.5,10.8
			c6.1,6.8,9,15.8,8.2,24.8c-0.2,2.2-1.9,5-4.3,5.9c-2.4,0.9-5.5,0-7.2-1.5c-2.3-2.1-4.4-4.2-6.5-6.3c-0.9-0.9-1.8-1.8-2.6-2.6
			c-2.5-2.5-5.2-2.5-7.7,0c-2.2,2.2-4.3,4.2-6.4,6.4c-2.4,2.5-2.4,4.9,0,7.4c1.7,1.8,3.4,3.4,5.2,5.2c1.1,1.1,2.3,2.3,3.3,3.3
			c2.4,2.4,3.2,5,2.2,7.7c-0.7,2-2.4,4.2-6.8,4.4C175.5,206.5,174.7,206.6,174,206.6z M146.5,160.6c1.7,3.4,2.4,7.3,2.3,11.8
			c-0.1,2.1,0,4,0.3,5.8c2.3,12.7,13.7,21.7,26.5,21c-1.1-1.1-2.2-2.2-3.1-3.1c-1.8-1.8-3.5-3.5-5.3-5.3c-5.3-5.5-5.3-12.2,0-17.6
			c2.2-2.2,4.3-4.3,6.5-6.5c5.4-5.3,12.5-5.3,17.9,0c0.9,0.9,1.8,1.8,2.6,2.7c1.8,1.9,3.6,3.7,5.5,5.5c0.4-6.6-2-13.2-6.5-18.3
			c-4.7-5.3-11.2-8.2-18.2-8.3h-1c-4.1-0.1-8.7-0.1-12.9-2.2l-4.8,4.8c-1.2,1.2-3.5,3.5-5.9,5.9C149,158,147.6,159.5,146.5,160.6z
       M200.3,175.3L200.3,175.3L200.3,175.3L200.3,175.3z"
    />
    <rect
      x="84.8"
      y="154.1"
      transform="matrix(0.7138 -0.7003 0.7003 0.7138 -95.5934 111.392)"
      width="7.4"
      height="37.1"
    />
  </svg>
)

export default ToolsIcon
