import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const DecoIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.main} {...props}>
    <path
      d="M29.9,166.2c-0.6,0-1.2-0.2-1.8-0.5c-1.2-0.7-1.9-2.2-1.7-3.6l12.3-75.8c0.2-1.1,0.9-2.1,2-2.6
				c1-0.5,2.3-0.4,3.3,0.2l48.1,28.3c1,0.6,1.6,1.6,1.7,2.8s-0.4,2.3-1.3,3l-60.4,47.5C31.4,165.9,30.7,166.2,29.9,166.2z
				 M44.8,92.5l-10,61.9l49.3-38.8L44.8,92.5z"
    />
    <path
      d="M126.9,191.5C126.9,191.5,126.9,191.5,126.9,191.5c-1.5,0-2.8-0.9-3.3-2.3l-27.4-71.7
				c-0.4-1.1-0.3-2.3,0.4-3.2c0.7-0.9,1.7-1.5,2.9-1.5c0,0,0,0,0,0l55.8,0.3c1.2,0,2.2,0.6,2.9,1.5c0.6,1,0.8,2.2,0.4,3.3
				l-28.4,71.4C129.6,190.6,128.3,191.5,126.9,191.5z M104.6,119.8l22.4,58.5l23.2-58.3L104.6,119.8z"
    />
    <path
      d="M220.1,169.5c-0.9,0-1.8-0.3-2.4-1l-55.5-53.1c-0.8-0.8-1.2-2-1-3.1s0.9-2.1,2-2.6l50.5-23.5
				c1-0.5,2.3-0.4,3.3,0.2c1,0.6,1.6,1.6,1.7,2.8l5,76.6c0.1,1.4-0.7,2.8-2,3.4C221.1,169.4,220.6,169.5,220.1,169.5z M170.7,113.9
				l45.3,43.3l-4.1-62.5L170.7,113.9z"
    />
    <path
      d="M155.4,120.1C155.4,120.1,155.4,120.1,155.4,120.1c-0.5,0-49.1-0.3-55.8-0.3c-7.3,0-10.2-1.2-10.8-1.4
				c-0.1,0-0.2-0.1-0.3-0.2C88.2,118,51.4,96.5,40.4,90c-12.2-7.3-15-17.8-15.1-18.3c-0.5-1.9,0.7-3.8,2.5-4.3
				c1.9-0.5,3.8,0.7,4.2,2.5c0.1,0.4,2.3,8.3,11.9,14c10.5,6.2,44.4,26.1,47.8,28c0.6,0.2,2.9,0.8,7.8,0.8c6.3,0,49.4,0.3,55.2,0.3
				c1.4-0.5,5.3-2,8.4-3.5c3.9-1.8,45.5-21.1,50.2-23.3c1.2-1.1,6.4-6.2,9.1-16.4c0.5-1.9,2.4-3,4.3-2.5c1.9,0.5,3,2.4,2.5,4.3
				c-3.8,14-11.5,20.1-11.8,20.4c-0.2,0.2-0.4,0.3-0.7,0.4c0,0-46.5,21.6-50.6,23.5c-4.2,1.9-9.4,3.8-9.6,3.9
        C156.2,120,155.8,120.1,155.4,120.1z"
    />
    <g stroke={COLORS.main}>
      <circle cx="49" cy="129.6" r="3.4" />
      <circle cx="60.7" cy="117.2" r="3.4" />
      <circle cx="52.4" cy="106.2" r="3.4" />
      <circle cx="126.7" cy="154.1" r="3.4" />
      <circle cx="138" cy="127.7" r="3.4" />
      <circle cx="120.8" cy="136.6" r="3.4" />
      <circle cx="207.2" cy="132.6" r="3.4" />
      <circle cx="205.6" cy="107.8" r="3.4" />
      <circle cx="192.1" cy="121.9" r="3.4" />
    </g>
  </svg>
)

export default DecoIcon
