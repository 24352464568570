import React from "react"
import styled from "styled-components"

import { COLORS, SIZES, BREAKPOINTS } from "src/constants"
import { smoothScroll } from "src/helpers/window"
import { Link } from "gatsby"

const Container = styled.footer`
  margin: 40px auto;
  padding: 10px 60px;
  max-width: ${BREAKPOINTS.maxWidth}px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    padding: 10px 20px;
    margin: 30px auto 10px;
  }
`
const Content = styled.div`
  color: ${COLORS.main};
  border-top: 2px solid ${COLORS.main};
  font-size: ${SIZES.normal};
  position: relative;
  padding: 8px 0;
  a {
    color: ${COLORS.main};
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.small};
    white-space: pre-line;
  }
`
const TopButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${COLORS.main};
  color: ${COLORS.light};
  font-size: ${SIZES.big};
  line-height: 1rem;
  font-weight: bold;
  padding: 18px 15px 3px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.large};
    padding: 12px 10px 0;
  }
`

const YEAR = new Date().getFullYear()

const Footer: React.FC = () => {
  const handleTop = (): void => {
    smoothScroll(document.body, 0, 1500)
  }

  return (
    <Container>
      <Content>
        © Ateliers Chutes Libres {YEAR}
        {"\n"} • <Link to="/cgv/">Conditions Générales de Vente</Link>
        <TopButton onClick={handleTop}>^</TopButton>
      </Content>
    </Container>
  )
}

export default Footer
