import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"

import { COLORS, SIZES, BREAKPOINTS } from "src/constants"
import { LinkProps, IWorkshop } from "src/types"
import DecoIcon from "src/svg/icon/Deco"
import ParticipantIcon from "src/svg/icon/Participant"
import ToolsIcon from "src/svg/icon/Tools"
import LampIcon from "src/svg/icon/Lamp"
import RulerIcon from "src/svg/icon/Ruler"
import QuestionIcon from "src/svg/icon/Time"
import CartIcon from "src/svg/Cart"
import { useCart } from "src/components/cart"

const Container = styled.header`
  height: 55px;
`
const Content = styled.div`
  max-width: ${BREAKPOINTS.maxWidth - 1}px;
  margin: 0 auto;
  height: 100%;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 10px;
    border: 2px solid ${COLORS.main};
    border-top: none;
    margin-bottom: 3px;
  }
`
const Nav = styled.nav<{ openMenu: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1;
  background-color: ${COLORS.light};
  @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
    /*transition: height 0.2s ease-in-out;*/
    height: auto;
    overflow: hidden;
    padding: 17px;
    ${Content} {
      ${(props): string => (props.openMenu ? "" : "display: none;")}
    }
  }
`

const linkStyle = css`
  font-weight: bold;
  padding: 2px 8px;
  display: block;
  font-size: ${SIZES.normal};
  white-space: nowrap;
  &.active,
  &:hover {
    background-color: ${COLORS.main};
    color: ${COLORS.light};
  }
  @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
    color: ${COLORS.main};
  }
`

const Menu = styled.div`
  width: 100%;
  button {
    ${linkStyle}
  }
  border-bottom: 2px solid ${COLORS.main};
  @media (min-width: ${BREAKPOINTS.maxWidth}px) {
    display: none;
  }
`

const NavLink: React.FC<LinkProps> = ({ exact, ...props }) => (
  <GatsbyLink activeClassName="active" partiallyActive={!exact} {...props} />
)

const Link = styled(NavLink)<{ to: string }>`
  ${linkStyle}
  ${(props) => (props.to === "/panier/" ? "margin-top: -3px;" : "")}
  > svg {
    vertical-align: -3px;
  }
  &:hover svg,
  &.active svg {
    fill: ${COLORS.light};
  }
  @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
    margin: 3px 0;
  }
`
const DropdownContainer = styled.div`
  position: relative;
  max-width: 100%;
`
const Button = styled.button`
  ${linkStyle}
  @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
    margin: 3px 0;
  }
  &.active {
    padding-bottom: 12px;
    margin-bottom: -10px;
  }
`
const Dropdown = styled.div`
  padding: 2px 0;
  @media (min-width: ${BREAKPOINTS.maxWidth}px) {
    position: absolute;
    top: 35px;
    right: 0;
    text-align: right;
    padding: 0;
    min-width: 160px;
  }
  background-color: ${COLORS.light};
  border: 2px solid ${COLORS.main};
  overflow: hidden;
  ${Link} {
    margin: 5px;
    padding-right: 2px;
    color: ${COLORS.main};
    &.active,
    &:hover {
      color: ${COLORS.light};
    }
    @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
      margin: 3px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;
    }
  }
`
const Icon = styled.span`
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 27px;
  background-color: ${COLORS.main};
  border-radius: 15px;
  text-align: center;
  svg {
    vertical-align: middle;
    width: 26px;
    height: 26px;
    fill: ${COLORS.light};
    [fill="${COLORS.main}"] {
      fill: ${COLORS.light};
    }
    [stroke="${COLORS.main}"] {
      stroke: ${COLORS.light};
    }
  }
  @media (max-width: ${BREAKPOINTS.maxWidth - 1}px) {
    flex: 0 0 auto;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    line-height: 21px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const Header: React.FC = () => {
  const navRef = useRef<HTMLDivElement>()
  const workshopsRef = useRef<HTMLDivElement>()
  const infosRef = useRef<HTMLDivElement>()
  const [openMenu, setMenu] = useState(false)
  const [open, setOpen] = useState<string>(null)
  const { getNumArticles } = useCart()

  const handleMenu = (): void => {
    setMenu(!openMenu)
  }

  const handleButton = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>): void => {
    const id = currentTarget.getAttribute("data-id")
    setOpen(open === id ? null : id)
  }

  useEffect(() => {
    const handleClickAway = ({ target }): void => {
      if (!workshopsRef.current.contains(target) && !infosRef.current.contains(target)) {
        setOpen(null)
      }
      if (!navRef.current.contains(target)) {
        setMenu(false)
      }
    }

    // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/ClickAwayListener/ClickAwayListener.js
    document.addEventListener("touchstart", handleClickAway)
    document.addEventListener("click", handleClickAway)
    return (): void => {
      document.removeEventListener("touchstart", handleClickAway)
      document.removeEventListener("click", handleClickAway)
    }
  }, [])

  const { workshops } = useStaticQuery(graphql`
    query {
      workshops: allContentfulWorkshop(sort: { fields: [position], order: ASC }) {
        nodes {
          slug
          name
          icon {
            svg {
              content
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Nav openMenu={openMenu} ref={navRef}>
        <Menu>
          <button className="active" onClick={handleMenu}>
            menu +
          </button>
        </Menu>
        <Content>
          <Link to="/" exact>
            accueil
          </Link>
          <DropdownContainer ref={workshopsRef}>
            <Button onClick={handleButton} data-id="workshops" className={open === "workshops" ? "active" : null}>
              nos ateliers +
            </Button>
            {open === "workshops" && (
              <Dropdown>
                {workshops.nodes.map(({ slug, name, icon }: IWorkshop) => (
                  <Link key={slug} to={`/ateliers/${slug}/`}>
                    {name.toLowerCase()} <Icon dangerouslySetInnerHTML={{ __html: icon.svg.content }} />
                  </Link>
                ))}
                <Link to="/groupes-entreprises/">
                  {"évènements "}
                  <Icon>
                    <DecoIcon />
                  </Icon>
                </Link>
              </Dropdown>
            )}
          </DropdownContainer>
          <Link to="/reserver/">réserver</Link>
          <Link to="/offrir/">offrir</Link>
          <Link to="/groupes-entreprises/">évènements</Link>
          <DropdownContainer ref={infosRef}>
            <Button onClick={handleButton} data-id="infos" className={open === "infos" ? "active" : null}>
              infos +
            </Button>
            {open === "infos" && (
              <Dropdown>
                <Link to="/valeurs/">
                  {"valeurs "}
                  <Icon>
                    <DecoIcon />
                  </Icon>
                </Link>
                <Link to="/equipe/">
                  {"équipe "}
                  <Icon>
                    <ParticipantIcon />
                  </Icon>
                </Link>
                <Link to="/infos/">
                  {"atelier "}
                  <Icon>
                    <ToolsIcon />
                  </Icon>
                </Link>
                <Link to="/tutoriels/">
                  {"tutos "}
                  <Icon>
                    <LampIcon />
                  </Icon>
                </Link>
                <Link
                  to="/"
                  state={{ anchor: "contact" }}
                  activeClassName={undefined}
                  onClick={(): void => setOpen(null)}
                >
                  {"contact "}
                  <Icon>
                    <RulerIcon />
                  </Icon>
                </Link>
                <Link to="/faq/">
                  {"questions "}
                  <Icon>
                    <QuestionIcon />
                  </Icon>
                </Link>
              </Dropdown>
            )}
          </DropdownContainer>
          <Link to="/panier/">
            <CartIcon /> {getNumArticles() || ""}
          </Link>
        </Content>
      </Nav>
    </Container>
  )
}

export default Header
