import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const LampIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.main} {...props}>
    <path
      d="M130.2,114.9h13.5c10.7,0,21.8,0,32.7,0c0.9,0,1.5-0.1,1.9-0.2c-0.1-0.4-0.3-0.9-0.7-1.7
			c-3.5-7.2-7.1-14.4-10.6-21.5c-6.6-13.3-13.3-27.1-20-40.6c-0.9-1.8-1.6-2.1-3.3-2.1c-8.7,0.1-17.7,0.1-27,0c-1.9,0-2.5,0.4-3.4,2
			c-6.9,14.4-14.1,28.9-21.1,43c-3.1,6.2-6.2,12.5-9.3,18.8c-0.5,1-0.7,1.7-0.8,2.1c0.4,0.1,1.3,0.2,2.5,0.2h32.1H130.2z
			 M156.6,123.2h-12.8h-13.5H117H84.8c-3.2,0-7.7-0.4-9.9-3.9c-2.1-3.5-0.6-7.5,0.8-10.3c3.1-6.3,6.2-12.5,9.3-18.8
			c6.9-14.1,14.1-28.6,21.1-42.9c2.2-4.6,5.8-6.7,10.9-6.7c9.1,0.1,18.1,0.1,26.7,0c5,0,8.6,2.1,10.8,6.8
			c6.6,13.6,13.3,27.2,19.9,40.6c3.5,7.1,7.1,14.3,10.6,21.5c1.9,3.9,2.1,7.3,0.5,9.9s-4.6,3.9-9.1,3.9L156.6,123.2"
    />
    <path
      d="M99.2,206.9c-0.9,0-1.4,0.1-1.6,1.9c-0.3,2,0.5,2.3,1.3,2.5c1.1,0.3,2.5,0.4,4.2,0.4c18.3,0.1,36.4,0,54.9,0
			c1.1,0,2.3,0,3.3-0.2c1.7-0.3,2.2-1,2-2.7c-0.1-1.5-0.1-1.9-2.4-2c-3.2,0-6.5,0-9.7,0c-2.4,0-4.8,0-7.2,0
			c-7.7,0-10.8-3.1-10.8-10.5v-12.2V179l-0.1-0.6l-0.1-41.6c0-1.8-0.2-2.6-0.2-2.9c-0.3-0.1-0.9-0.2-2.2-0.2c-1.6,0-2.4,0.1-2.7,0.2
			c-0.1,0.4-0.3,1.2-0.3,3l0.1-3.7v40.4l0.1,22.1c0,3-0.2,6.3-2.5,8.6c-2.3,2.4-5.6,2.6-8.6,2.6h-2c-5.1,0-10.3,0-15.4,0L99.2,206.9
			 M131.4,220.1c-9.5,0-18.9,0-28.5,0c-2.5,0-4.5-0.3-6.4-0.8c-5.2-1.5-8-5.9-7.2-11.5c0.8-5.7,4.5-9.1,9.9-9.1h0.2
			c5.1,0.1,10.3,0.1,15.3,0h2c1.5,0,2.2-0.1,2.6-0.2c0.1-0.4,0.2-1.2,0.2-2.8c0-17.2-0.1-4.9-0.1-22.1v-19.8v-20.6l-0.1,3.7
			c0-3.3,0.3-6.7,2.7-9c2.3-2.2,5.5-2.5,8.6-2.5c8.8,0.1,10.6,4.6,10.6,11.4l0.1,41.6l0.1,0.6v5.2c0,4,0,8.1,0,12.1
			c0,1.2,0.1,1.8,0.1,2.1c0.4,0.1,1.1,0.2,2.5,0.2h7.2c3.2,0,6.5,0,9.8,0c6.3,0,10.1,3.4,10.6,9.6c0.5,5.8-2.9,10.2-8.7,11.4
			c-1.7,0.3-3.4,0.3-4.9,0.4h-26.6"
    />
  </svg>
)

export default LampIcon
